// CHANGES TO THIS FILE MUST BE CAREFULLY REVIEWED
// CHANGES TO THIS FILE WILL RESULT IN IMAGE CACHE INVALIDATION

const NAMED_TRANSFORMATIONS = {
  PROFILE_THUMBNAIL: {
    resize: {
      type: 'thumb',
      height: 60,
      width: 60,
    },
    gravity: 'face',
    quality: 100,
    zoom: 0.7,
  },
  PROFILE_IMAGE: {
    resize: {
      type: 'fill',
      height: 296,
      width: 200,
    },
    gravity: 'auto',
    quality: 100,
  },
  POSTER: {
    resize: {
      type: 'pad',
      height: 296,
      width: 200,
    },
    background: 'auto',
    quality: 100,
  },
  ORG_LOGO: {
    resize: {
      type: 'pad',
      height: 296,
      width: 200,
    },
    quality: 100,
    background: 'auto',
  },
  SLUG_POSTER: {
    resize: {
      type: 'pad',
      height: 74,
      width: 50,
    },
    quality: 100,
    background: 'auto',
  },
  SLUG_MEDIA: {
    resize: {
      type: 'fill',
      height: 231,
      width: 413,
    },
    gravity: 'auto',
    quality: 100,
  },
  PRODUCTION_MEDIA_SLUG: {
    resize: {
      type: 'fill',
      height: 194,
      width: 305,
    },
    gravity: 'auto',
    quality: 100,
  },
  AOS_LOGO: {
    resize: {
      type: 'pad',
      height: 198,
      width: 198,
    },
    quality: 100,
    background: 'auto',
  },
  GALLERY_PORTRAIT_VIEW: {
    resize: {
      type: 'fill',
      height: 500,
      width: 367,
    },
    gravity: 'auto',
    quality: 'auto',
  },
  GALLERY_LANDSCAPE_VIEW: {
    resize: {
      type: 'fill',
      height: 496,
      width: 740,
    },
    gravity: 'auto',
    quality: 'auto',
  },
  VIDEO_CONTENT_AWARE_THUMBNAIL: {
    resize: {
      type: 'fill',
    },
    gravity: 'auto:faces',
    quality: 100,
  },
  MEDIA_WIDGET_BANNER: {
    resize: {
      type: 'fill',
    },
    quality: 100,
    gravity: 'auto:faces',
  },
  ASSOCIATE_ITEM_POSTER: {
    resize: {
      type: 'fill',
      height: 304,
      width: 198,
    },
    gravity: 'auto',
    quality: 100,
    radius: 8,
  },
  LIGHTBOX_PREVIEW: {
    gravity: 'auto',
    quality: 100,
  },
  FILMSTRIP_THUMBNAIL: {
    resize: {
      height: 50,
    },
    quality: 100,
    radius: 8,
  },
  CUSTOMER_LOGO: {
    resize: {
      type: 'lpad',
      height: 78,
      width: 130,
    },
    quality: 100,
    background: 'transparent',
  },
};

const IMAGE_TYPE_TRANSFORMATION_MAP = {
  PROFILE_THUMBNAIL: NAMED_TRANSFORMATIONS.PROFILE_THUMBNAIL,
  PROFILE_IMAGE: NAMED_TRANSFORMATIONS.PROFILE_IMAGE,
  POSTER: NAMED_TRANSFORMATIONS.POSTER,
  ORG_LOGO: NAMED_TRANSFORMATIONS.ORG_LOGO,
  SLUG_POSTER: NAMED_TRANSFORMATIONS.SLUG_POSTER,
  GALLERY_PORTRAIT_VIEW: NAMED_TRANSFORMATIONS.GALLERY_PORTRAIT_VIEW,
  GALLERY_LANDSCAPE_VIEW: NAMED_TRANSFORMATIONS.GALLERY_LANDSCAPE_VIEW,
  AOS_LOGO: NAMED_TRANSFORMATIONS.AOS_LOGO,
  PRODUCTION_MEDIA_SLUG: NAMED_TRANSFORMATIONS.PRODUCTION_MEDIA_SLUG,
  MEDIA_WIDGET_BANNER: NAMED_TRANSFORMATIONS.MEDIA_WIDGET_BANNER,
  VIDEO_CONTENT_AWARE_THUMBNAIL: NAMED_TRANSFORMATIONS.VIDEO_CONTENT_AWARE_THUMBNAIL,
  ASSOCIATE_ITEM_POSTER: NAMED_TRANSFORMATIONS.ASSOCIATE_ITEM_POSTER,
  FILMSTRIP_THUMBNAIL: NAMED_TRANSFORMATIONS.FILMSTRIP_THUMBNAIL,
  SLUG_MEDIA: NAMED_TRANSFORMATIONS.SLUG_MEDIA,
  CUSTOMER_LOGO: NAMED_TRANSFORMATIONS.CUSTOMER_LOGO,
};

export default IMAGE_TYPE_TRANSFORMATION_MAP;
